<template>
    <div class="p-info-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("Employment History") }}</div>
            <router-link class="edit-button" :to="{ name: 'candidate.employments.create'}">
                <span class="edit-icon"><i class="eicon e-plus"></i></span>
                <span>{{ $t("add experience") }}</span>
            </router-link>
        </div>
        <div class="employment-history row row-cols-md-2" v-if="isContentLoading">
            <div class="col" v-for="index in 4" :key="index">
                <profile-card-loader></profile-card-loader>
            </div>
        </div>
        <template v-else>
            <h4 class="empty-message" v-if="isEmpty">{{ $t("no employment history found") }}</h4>
            <div class="employment-history" v-else>
                <draggable :list="employments" class="row" ghost-class="ghost" chosen-class="chosen" drag-class="drag" @end="sortEmploymentHistory">
                    <div class="col-md-6 align-items-stretch" draggable="true" @dragstart="startEmploymentHistoryDrag" @dragend="endEmploymentHistoryDrag" v-for="(employment, index) in employments">
                        <div class="p-info-card form-box" :key="index">
                            <div class="p-info-icon">
                                <i class="eicon e-briefcase"></i>
                            </div>
                            <div class="p-info-text">
                                <h5> {{ employment.designation }}</h5>
                                <ul>
                                    <li>{{ employment.company_name }}</li>
                                    <li>{{ $t(employment.department) }} {{ $t("Department") }}</li>
                                    <li>
                                        {{ dateFormat(employment.from_date) }} - {{ employment.to_date ? dateFormat(employment.to_date) : '' }} {{ employment.is_currently_working ? $t('present') : '' }}
                                    </li>
                                    <li v-if="employment.responsibilities">
                                        <span class="label__full--eraseble" style="font-size:13px;">
                                            <strong>{{ $t('Responsibilities') }}:</strong>
                                            <span v-if="employment.showFullResponsibility">
                                                {{ employment.responsibilities }}
                                                <a href="javascript:void(0)" @click.prevent="loadLessMore(employment)">
                                                    <i class="eicon  e-sort-up"></i> {{ $t(`less`) }}
                                                </a>
                                            </span>
                                            <span v-else>
                                                {{ employment.shortResponsibility }}
                                                <a href="javascript:void(0)" @click.prevent="loadLessMore(employment)">
                                                    <i class="eicon  e-sort-down"></i> {{ $t(`more`) }}
                                                </a>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="p-info-control">
                                <a href="javascript:void(0)" class="control-button control-button--info has_bg_color" @click="editEmployment(employment.id)">
                                    <div class="control-button__icon">
                                        <i class="eicon e-pencil"></i>
                                    </div>
                                    <span>{{ $t("Edit") }}</span>
                                </a>
                                <a href="javascript:void(0)" class="control-button control-button--danger has_bg_color" @click="deleteEmployment(employment.id, index)">
                                    <div class="control-button__icon">
                                        <i class="eicon e-delete"></i>
                                    </div>
                                    <span>{{ $t("Delete") }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </template>
    </div>
</template>
<script>
import {getCandidateEmployment} from "../../../../app/api/CommonRequest";
import ProfileCardLoader from "../../../../components/_loaders/ProfileCardLoader";
import client from "../../../../app/api/Client";
import draggable from "vuedraggable";

export default {
    name: "List",
    components: {
        ProfileCardLoader,
        draggable
    },
    data() {
        return {
            employments: [],
            isContentLoading: false,
        }
    },
    computed: {
        isEmpty() {
            return this.employments.length === 0;
        }
    },
    methods: {
        loadLessMore(employment) {
            employment.showFullResponsibility = !employment.showFullResponsibility;
        },
        async getEmployments() {
            this.isContentLoading = true;
            try {
                let {data} = await getCandidateEmployment();

                this.employments = data.map(employee => {
                    employee.showFullResponsibility = false;
                    employee.shortResponsibility = employee.responsibilities ? _.trim(employee.responsibilities.substr(0, 50)) : '';
                    return employee;
                });
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        deleteEmployment(employmentId, index) {
            let message = {
                title: this.$t('confirmation'),
                body: this.$t('Are you sure you want to delete this employment record?')
            };
            this.$dialog.confirm(message).then(() => {
                client().delete(`/candidate/employment/${employmentId}/delete`)
                    .then(({data: {data, message}}) => {
                        this.employments.splice(index, 1);
                        this.$toast.success(message);
                    }).catch(error => {
                });
            });
        },
        editEmployment(employmentId) {
            this.$router.push({
                name: 'candidate.employments.edit', params: {id: employmentId}
            });
        },
        async sortEmploymentHistory(evt) {
            client().post(`candidate/employment/re-order`, {'employments': this.employments})
                .then(response => {

                }).catch(error => {
                });
        },
        startEmploymentHistoryDrag(evt) {
            let elem = evt.target;

            setTimeout(() => {
                elem.style.opacity = 0;
            }, 0);
        },
        endEmploymentHistoryDrag(evt) {
            let elem = evt.target;
            elem.style.opacity = 1;
        },
    },

    mounted() {
        this.getEmployments();
    }

}
</script>

<style scoped>
    .chosen {
        opacity: 0.5;
    }

    .chosen.ghost {
        opacity: 0;
    }

    .drag {
        opacity: 0.5;
    }

    .label__full--eraseble {
        white-space: pre-line;
    }

    .form-box {
        padding: 50px 25px;
    }
    .employment-history {
        display: grid;
    }
    .employment-history .p-info-card ul {
        list-style: none;
    }
</style>

